import { graphql, useStaticQuery } from 'gatsby';
import React from 'react';
import styles from '~components/About/Bio/Bio.styles.scss';
import Links from '~components/About/Links/Links';
import profilePic from '~content/assets/profile-pic.jpg';

export default function Bio() {
    const {
        site: {
            siteMetadata: {
                author: { summary }
            }
        }
    } = useStaticQuery(
        graphql`
            query {
                site {
                    siteMetadata {
                        author {
                            summary
                        }
                    }
                }
            }
        `
    );

    return (
        <div className={styles.bio}>
            <img src={profilePic} alt="Christopher Aguilar" className={styles.bioPic} />
            <div className={styles.bioDescription}>
                <p>{summary}</p>
                <Links />
            </div>
        </div>
    );
}
