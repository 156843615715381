import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import styles from '~components/About/Links/Icon/Icon.styles.scss';

export default function Icon({ href, icon, prefix }) {
    return (
        <a className={styles.icon} href={href} target="_blank" rel="noopener noreferrer">
            <FontAwesomeIcon icon={[prefix, icon]} size="2x" />
        </a>
    );
}
