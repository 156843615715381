import React from 'react';
import Bio from '~components/About/Bio/Bio.component';
import Projects from '~components/About/Projects/Projects.component';
import Layout from '~components/Layout/Layout.component';
import SEO from '~components/SEO';

export default function About() {
    return (
        <Layout>
            <SEO title="About Me" />
            <h1>About Me</h1>
            <Bio />
            <Projects />
        </Layout>
    );
}
