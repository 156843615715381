import { graphql, useStaticQuery } from 'gatsby';
import React from 'react';
import styles from '~components/About/Links/Links.styles.scss';
import Icon from '~components/About/Links/Icon/Icon.component';

export default function Links() {
    const {
        site: {
            siteMetadata: {
                author: { links }
            }
        }
    } = useStaticQuery(
        graphql`
            query {
                site {
                    siteMetadata {
                        author {
                            links {
                                url
                                icon
                                prefix
                            }
                        }
                    }
                }
            }
        `
    );
    return (
        <div className={styles.iconContainer}>
            {links.map((link) => (
                <Icon key={link.url} href={link.url} icon={link.icon} prefix={link.prefix} />
            ))}
        </div>
    );
}
