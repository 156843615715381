import React, { useEffect, useState } from 'react';
import Project from '~components/About/Projects/Project/Project.component';
import { useStaticQuery, graphql } from 'gatsby';

export default function Projects() {
    const [projects, setProjects] = useState(<p>Loading ...</p>);

    const {
        site: {
            siteMetadata: { repoListUrl }
        }
    } = useStaticQuery(
        graphql`
            query {
                site {
                    siteMetadata {
                        repoListUrl
                    }
                }
            }
        `
    );

    useEffect(() => {
        fetch(repoListUrl)
            .then((res) => res.json())
            .then((data) =>
                setProjects(
                    data.map(({ description, html_url: url, id, name, pushed_at: lastUpdated }) => (
                        <Project key={id} description={description} lastUpdated={lastUpdated} name={name} url={url} />
                    ))
                )
            );
    });

    return (
        <div>
            <h2>Recent Work</h2>
            <div>{projects}</div>
        </div>
    );
}
