import React from 'react';
import { format } from 'date-fns';
import styles from '~components/About/Projects/Project/Project.styles.scss';

export default function Project({ description, lastUpdated, name, url }) {
    return (
        <div>
            <h3 className={styles.projectName}>
                <a href={url} target="_blank" rel="noopener noreferrer">
                    {name}
                </a>
            </h3>
            <small className={styles.lastUpdated}>Last Updated {format(new Date(lastUpdated), 'MMMM dd, yyyy')}</small>
            <p className={styles.projectDescription}>{description || 'No Description'}</p>
        </div>
    );
}
